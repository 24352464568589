var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "product-blocks" },
    _vm._l(_vm.items, function (item, index) {
      return _c("li", { key: index }, [
        _c("picture", [
          item.imageSrc
            ? _c("img", { attrs: { src: item.imageSrc, alt: "" } })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "inner" },
            [
              item.title
                ? _c("h4", {
                    key: "title",
                    domProps: { innerHTML: _vm._s(item.title) },
                  })
                : _vm._e(),
              _vm._v(" "),
              item.text
                ? _c("div", {
                    key: "text",
                    domProps: { innerHTML: _vm._s(item.text) },
                  })
                : _vm._e(),
              _vm._v(" "),
              item.list
                ? _c(
                    "ul",
                    { key: "list" },
                    _vm._l(item.list, function (listItem, listIndex) {
                      return _c("li", { key: listIndex }, [
                        _vm._v(
                          "\n            " + _vm._s(listItem) + "\n          "
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("zg-button", { key: "button", attrs: { type: "button" } }, [
                item.button
                  ? _c("a", { attrs: { href: item.button.href } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.button.text) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }